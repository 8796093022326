import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IFooterProp{
}

function FootArea (props: IFooterProp) {
  //const navigate = useNavigate();

  return (
    <>
        <footer className="footer py-4">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-4 text-lg-start">
            <Link className="link-dark text-decoration-none me-3" to="/">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ height: "8%", width: "8%" }} src="assets/img/logo-nobackground.svg" alt='Startseite'></img>
                <p style={{ marginLeft: "5px" }}>© von Platen IT 2023</p>
              </div>
        
{/*                   <img style={{height: "15%", width: "15%"}} src="assets/img/logo-nobackground.svg" alt='Startseite'></img>
                  <p style={{marginLeft: "5px"}}>© von Platen IT 2023</p>
 */}                  
            </Link>
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
          <a className="btn btn-dark btn-social mx-2" target='_blank' rel="noopener noreferrer" href="https://twitter.com/BerndPlaten" aria-label="Twitter"> <i className="fab fa-twitter" /></a>
          {/*<a className="btn btn-dark btn-social mx-2" target='_blank' rel="noopener noreferrer" href="https://de-de.facebook.com/public/Bernd-Platen" aria-label="Facebook"> <i className="fab fa-facebook-f" /></a>*/}
          <a className="btn btn-dark btn-social mx-2" target='_blank' rel="noopener noreferrer" href="https://de.linkedin.com/in/bernd-von-platen-137717219" aria-label="LinkedIn"> <i className="fab fa-linkedin-in" /></a>
        </div>
        <div className="col-lg-4 text-lg-end">
          <Link className="link-dark text-decoration-none me-3" to="/impressum">Impressum</Link>
          <Link className="link-dark text-decoration-none me-3" to="/privacy">Datenschutzerklärung</Link>
        </div>
        </div> 
        </div>
        </footer>
    </>
  );
}

export default FootArea;