import React,{ useEffect }  from 'react'
import { useLocation } from 'react-router-dom';

import Navigation from '../components/Navigation'
import Contact from '../components/Contact'
import About from './About'
import Portfolio from './Portfolio'
import Services from './Services'
import Team from './Team'

type Props = {}

const MainPage = (props: Props) => {
    const location = useLocation();

    useEffect(() => {
      // Scrollen an den Anfang der Komponente, wenn sie gerendert wird
      window.scrollTo(0, 0);
    }, [location.pathname]); // Achte darauf, die Location zu überwachen

  return (
    <>
        <Navigation />
        <Services/>
        {/*<Portfolio/>*/}
        <About/>
        <Team/>
        {/*<Contact/>*/}
    </>
  )
}

export default MainPage