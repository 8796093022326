import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';

/* import { Navigation } from './components/Navigation';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Team from './pages/Team';
import Contact from './components/Contact'; */

import MainPage from "./pages/MainPage";
import Impressum from './pages/Impressum';
import Privacy from './pages/Privacy';
import FootArea from './components/FootArea';

const App = () => {
  return (
    <>
      <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
          <FootArea />
      </Router>
    </>
  );
}


export default App;