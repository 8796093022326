import * as React from 'react';

export interface IAppProps {
}

function Navigation (props: IAppProps) {
  return (
<>
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div className="container">
        <a className="navbar-brand" href="#page-top"><img src="assets/img/logo-nobackground.svg" alt="von Platen IT" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i className="fas fa-bars ms-1" />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
            <li className="nav-item"><a className="nav-link" href="#services">Service</a></li>
            {/*<li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>*/}
            <li className="nav-item"><a className="nav-link" href="#about">Über</a></li>
            <li className="nav-item"><a className="nav-link" href="#team">Team</a></li>
            {/*<li className="nav-item"><a className="nav-link" href="#contact">Kontakt</a></li>*/}
        </ul>
        </div>
    </div>
    </nav>      

    <header className="masthead">
    <div className="container">
      <img src="assets/img/logo-nobackground.svg" alt="..." />
        <div className="masthead-subheading" style={{ color: "#222" }}>Herzlich Willkommen bei von Platen IT</div>
        <div className="masthead-subheading text-uppercase" style={{ color: "#222" }}>Software-entwicklung & Webdienstleistungen</div>
        {/*<a className="btn btn-info btn-xl text-uppercase"  style={{ color: "#229" }} href="#services">Weiter Informationen</a>*/}
    </div>
    </header>
</>
  );
}

export default Navigation;


