import React from 'react'

type Props = {}

const Team = (props: Props) => {
  return (
    <>
{/* Team*/}
<section className="page-section bg-light" id="team">
  <div className="container">
    <div className="text-center">
      <h2 className="section-heading text-uppercase">Mitglieder des Teams</h2>
      <h3 className="section-subheading text-muted">Übersicht der Mitarbeiter.</h3>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="assets/img/team/team_choki.png" alt="..." />
          <h4>Choki</h4>
          <p className="text-muted">Wohlfühlbeauftragter</p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="assets/img/team/team_ari.png" alt="..." />
          <h4>Ari</h4>
          <p className="text-muted">Design</p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="assets/img/team/team_bernd.png" alt="..." />
          <h4>Bernd</h4>
          <p className="text-muted">Programmierung</p>
          {/*
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Twitter Profile"><i className="fab fa-twitter" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile"><i className="fab fa-facebook-f" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker LinkedIn Profile"><i className="fab fa-linkedin-in" /></a>
          */}
        </div>
      </div>
    </div>
    <div className="row">
      {/*<div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div>*/}
    </div>
  </div>
</section>

{/* Clients*/}
{/*
<div className="py-5">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-3 col-sm-6 my-3">
        <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/microsoft.svg" alt="..." aria-label="Microsoft Logo" /></a>
      </div>
      <div className="col-md-3 col-sm-6 my-3">
        <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/google.svg" alt="..." aria-label="Google Logo" /></a>
      </div>
      <div className="col-md-3 col-sm-6 my-3">
        <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/facebook.svg" alt="..." aria-label="Facebook Logo" /></a>
      </div>
      <div className="col-md-3 col-sm-6 my-3">
        <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/ibm.svg" alt="..." aria-label="IBM Logo" /></a>
      </div>
    </div>
  </div>
</div>
 */}


    </>
  )
}

export default Team