import * as React from 'react';

export interface IServicesProps {
}

export default function Services (props: IServicesProps) {
  return (
    <>
{/* Services*/}
<section className="page-section" id="services">
  <div className="container">
    <div className="text-center">
      <h2 className="section-heading text-uppercase">Services</h2>
      <h3 className="section-subheading text-muted">Sie finden hier folgende Leistungen:</h3>
    </div>
    <div className="row text-center">
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fas fa-circle fa-stack-2x text-primary" />
          <i className="fas fa-compass-drafting fa-stack-1x fa-inverse" />
        </span>
        <h4 className="my-3">Software-Entwicklung</h4>
        <p className="text-muted">Suchen Sie einen kompetenten Partner für FullStack Softwareentwicklung mit einem starken Fokus auf frontend und React? Als freier Mitarbeiter oder unternehmerischer Auftragsnehmer stehe ich Ihnen mit meiner langjährigen Erfahrung und Leidenschaft für innovative Technologien zur Verfügung. Gemeinsam können wir Ihre Ideen in hochwertige Softwarelösungen verwandeln und Ihre Projekte erfolgreich umsetzen.</p>
      </div>
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fas fa-circle fa-stack-2x text-primary" />
          <i className="fas fa-user-pen fa-stack-1x fa-inverse" />
        </span>
        <h4 className="my-3">Beratung und Schulung</h4>
        <p className="text-muted">Als Berater für Softwareentwicklung unterstütze ich Sie dabei, Software mit einer benutzerfreundlichen Oberfläche und herausragender Usability zu realisieren. Zusätzlich biete ich Schulungen im Bereich der C#- und TypeScript-Entwicklung an, um Ihre Entwickler auf dem neuesten Stand zu halten und ihnen die Werkzeuge zu geben, um effektive und hochwertige Software zu erstellen.</p>
      </div>
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fas fa-circle fa-stack-2x text-primary" />
          <i className="fas fa-earth-americas fa-stack-1x fa-inverse" />
        </span>
        <h4 className="my-3">Webdesign</h4>
        <p className="text-muted">Um Ihr Unternehmen oder Vorhaben im Internet erfolgreich zu repräsentieren, berücksichtige ich nicht nur die Entwicklung funktionsreicher Webanwendungen, sondern auch die Anbindung an Social Media Plattformen. Durch die Integration von Social Media in Ihre Webanwendung können Sie Ihre Inhalte viral verbreiten, Ihre Zielgruppe erreichen und eine starke Online-Präsenz aufbauen.</p>
      </div>
    </div>
  </div>
</section>
      
    </>
  );
}




