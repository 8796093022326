import * as React from 'react';

export interface IAboutProps {
}

export default function About (props: IAboutProps) {
  return (
    <>
      

{/* About*/}
<section className="page-section" id="about">
  <div className="container">
    <div className="text-center">
      <h2 className="section-heading text-uppercase">Über</h2>
      <h3 className="section-subheading text-muted">Projekte und Erfahrungen</h3>
    </div>
    <ul className="timeline">
      <li>
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>1997-2000</h4>
            <h4 className="subheading">IBM Deutschland GmbH</h4>
          </div>
          <div className="timeline-body"><p className="text-muted">Von 1997 bis 2000 war ich bei IBM Deutschland GmbH im Produkt Valuta-Direct tätig, welches den bankinternen Devisenhandel unterstützte. Als C++ Entwickler in einer studentischen Hilfskraftposition war ich verantwortlich für die plattformübergreifende UI Entwicklung auf Windows NT, IBM OS2 und Sun Solaris. Dabei setzte ich Tools wie Microsoft Visual C++, IBM Visual Age C++ und Star-View ein, um innovative Lösungen für den Devisenhandel zu entwickeln.</p></div>
        </div>
      </li>
      <li className="timeline-inverted">
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>2000-2011</h4>
            <h4 className="subheading">Ascom Deutschland GmbH</h4>
          </div>
          <div className="timeline-body"><p className="text-muted">Von 2000 bis 2011 war ich bei Ascom Deutschland GmbH im Produkt DüLAS tätig, das die deutschlandweite Verwaltung der Mietleitungen und Anschlüsse für T-Mobile ermöglichte. In meiner Rolle als Projektleiter war ich verantwortlich für die Entwicklung der Datenbank, der serverseitigen Backend-Logik und des UI. Während dieser Zeit habe ich Tools wie Solaris Unix Server, PL SQL für Oracle-Datenbankentwicklung, C++ mit MS Visual C++, Unix Bash-Skript-Programmierung, UML mit Rational Rose, Crystal Reports und CVS Quellcodeverwaltung eingesetzt.</p>
          <br/>
          <p className="text-muted">Später arbeitete ich an dem Produkt IPS+, das Routing-Management, Dispute-Management und Prebilling für den Intercarrierbetrieb der deutschen Telekom umfasste. In dieser Rolle war ich für die Entwicklung und Administration zuständig. Ich erweiterte die ASP.net Weboberfläche in C#, führte Systemüberwachungen mittels Python-Skripten durch und war für die Datenbankentwicklung und Administration von SQL Server-Clustern und HP Storage-Systemen verantwortlich.</p>
          <br/>
          <p className="text-muted">Während meiner Tätigkeit nutzte ich Tools wie C# Programmierung, ASP.net Entwicklung und Administration, Webentwicklung in HTML und CSS, SQL Server Entwicklung und Administration, SQL Server Clustering, HP SAN Administration und Wartung, Quest Foglight Systemüberwachung sowie Systemüberwachung mit Python-Skripten.</p></div>
        </div>
      </li>
      <li>
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>2011-2014</h4>
            <h4 className="subheading">Eigenes Unternehmen</h4>
          </div>
          <div className="timeline-body"><p className="text-muted">Von 2011 bis 2014 war ich als selbstständiger Softwareentwickler tätig und entwickelte das Produkt OSCAR, ein Internet-Bestellsystem für Schnellrestaurants mit Lieferservice. In meiner Rolle als freier Unternehmer war ich für die Eigenentwicklung des Bestellsystems verantwortlich.</p>
          <br/>
          <p className="text-muted">Bei der Entwicklung von OSCAR setzte ich Tools wie ASP.net Webanwendung in C#, SQL-Server Entwicklung, Iron.Python (Python für .NET) und PowerShell ein. Diese Technologien ermöglichten es mir, eine benutzerfreundliche und effiziente Webanwendung zu entwickeln, die den Bestellprozess für Schnellrestaurants und deren Lieferservice optimierte.</p></div>
        </div>
      </li>
      <li className="timeline-inverted">
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>2014-2022</h4>
            <h4 className="subheading">ene't GmbH</h4>
          </div>
          <div className="timeline-body"><p className="text-muted">Von 2014 bis 2022 war ich bei ene't GmbH tätig und arbeitete an verschiedenen Produkten. Zunächst entwickelte ich ein internes Tool namens 'CSV Datenexport', das einen performanten Datenexport aus SQL-Server und MS-Access ermöglichte. In meiner Rolle war ich verantwortlich für Konzept, Design und Entwicklung des Datenexport-Tools. Dabei setzte ich auf eine Toolchain bestehend aus C# mit Visual Studio 2019, ADO.NET für In-Memory-Caching, Multithreading zur Performance-Optimierung und SQL-Server-Programmierung mit CLR Procedures.</p>
          <br/>
          <p className="text-muted">Später war ich an der Entwicklung und Administration von 'Ene't navigator RDP' beteiligt, einem SaaS-System. In dieser Funktion arbeitete ich mit Power-Shell für objektorientierte Skripte und hatte Erfahrungen mit VMware, VSPhere und Server-Virtualisierung. Des Weiteren verwendete ich Active Directory und RDP-Servlets für die Entwicklung und Administration interner Tools im Zusammenhang mit dem SaaS-System.</p>
          <br/>
          <p className="text-muted">Des Weiteren war ich als Webfrontend-Entwickler für den 'Ene't navigator' tätig. Hierbei setzte ich eine Toolchain bestehend aus Java SpringBoot, Angular Version 9, Typescript, Postgres SQL, JUNIT Testframework und Docker Virtualisierung ein.</p>
          <br/>
          <p className="text-muted">Zudem entwickelte ich einen REST-Webservice in .NET Core für den 'Ene't navigator'. Dabei verwendete ich die Toolchain Visual Studio 2019, C#, das .NET Core Framework 3.1, Postgres SQL und Docker Virtualisierung für die Umsetzung von Microservices.</p></div>
        </div>
      </li>
      <li>
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/7.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>2022</h4>
            <h4 className="subheading">3M Health</h4>
          </div>
          <div className="timeline-body">
            <p className="text-muted">Im Jahr 2022 war OINK Media GmbH als Subunternehmen für 3M Health IS tätig und arbeitete an der Entwicklung des Webfrontends für das Produkt "3M Health Information Systems". Die Hauptaufgabe bestand darin, eine benutzerfreundliche und ansprechende Benutzeroberfläche zu erstellen.</p>
            <br/>
            <p className="text-muted">Für die Entwicklung wurde die Toolchain von Jet Brains, einschließlich WebStorm und ReSharper, verwendet. Die Programmiersprache C# und das .NET Core Framework in der Version 3.1 dienten als Grundlage für die Implementierung. Die Datenbankanbindung erfolgte über Postgres SQL.</p>
            <br/>
            <p className="text-muted">Darüber hinaus wurde Docker Virtualisierung eingesetzt, um eine effiziente und skalierbare Umgebung für die Anwendung bereitzustellen. Durch den Einsatz dieser Technologien konnte OINK Media GmbH erfolgreich zum Erfolg des 3M Health Information Systems beitragen.</p></div>
        </div>
      </li>
      <li className="timeline-inverted">
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/8.jpg" alt="..." /></div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>2023</h4>
            <h4 className="subheading">Schleupen SE</h4>
          </div>
          <div className="timeline-body">
            <p className="text-muted">Tätig als Webentwickler bei Schleupen SE und dort an der Entwicklung des Webfrontends, des Backends und internen Tools für das Produkt Schleupen SE mitgearbeitet. Mit meiner Expertise in React JS, TypeScript und Redux habe ich interaktive und ansprechende Benutzeroberflächen erstellen und gleichzeitig die Effizienz des Backends verbessern. Durch den Einsatz von Jest führe ich umfangreiche Tests durch, um sicherzustellen, dass die Anwendungen stabil und fehlerfrei sind.</p>
            <br/>
            <p className="text-muted">Bei der  Arbeit verwende ich Visual Studio Code als Entwicklungsumgebung, um effizienten Code zu schreiben und den Entwicklungsprozess zu optimieren. Team Foundation Server unterstützt bei der Versionskontrolle und Zusammenarbeit im Team.</p>
            </div>
        </div>
      </li>            
      <li className="timeline-inverted">
        <div className="timeline-image">
          <h4>
            Be Part
            <br />
            Of My
            <br />
            Story!
          </h4>
        </div>
      </li>
    </ul>
  </div>
</section>


    </>
  );
}
